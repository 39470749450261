<template>
  <div style="height:100%">
    <div style="padding: 20px;height: 800px">
      <!-- 搜素框 -->
      <el-form :inline="true" :model="params"
        style="padding-top:20px;padding-left:20px;margin-bottom:20px;background-color:#fff;border-radius:8px"
        size="small">
        <el-form-item label="角色名称">
          <el-input v-model="params.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="checkData" size="mini">查询</el-button>
          <el-button type="info" @click="reset" size="mini">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div style="height:82%;background-color:#fff;border-radius:8px;padding-top: 8px;">
        <el-button type="primary" size="small" style="float:right;margin-top:20px;margin-right:20px;margin-bottom:20px"
          @click="add()" v-show="authorization('/home-admin/routineManage/role-list/add')">新增</el-button>
        <el-table :data="tableData" stripe style="padding:0 20px;" height="80%" size="small"
          :header-cell-style="{ 'background-color': '#84aace4a', 'color': '#343333', 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }">
          <el-table-column prop="roleName" label="角色名称"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑" placement="top-end" v-show="authorization('/home-admin/routineManage/role-list/edit')">
                <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="edit(scope.row)"
                  style="margin-left:10px"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="授权" placement="top-end" v-show="authorization('/home-admin/routineManage/role-list/authorization')">
                <el-button type="warning" icon="el-icon-set-up" circle size="mini" @click="doAuthorization(scope.row)"
                  style="margin-left:10px"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-end" style="margin-left:10px" v-show="authorization('/home-admin/routineManage/role-list/del')">
                <el-popconfirm title="确认删除吗？" @confirm="deleteType(scope.row)">
                  <el-button content="删除" slot="reference" type="danger" icon="el-icon-delete" circle
                    size="mini"></el-button>
                </el-popconfirm>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding-top:20px;height:40px;background-color:#fff;width: 100%;border-radius:8px;text-align:right">
          <el-pagination background :page-sizes="sizes" layout="total,sizes,prev,pager,next" :total="total"
            @current-change="handleCurrentChange" @size-change="sizeChange"></el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <el-dialog title="新增" :visible.sync="dialogFormAdd" width="700px" :close-on-click-modal="false">
      <el-form :model="SysRoleArray" :rules="rules" ref="SysRoleArray" label-width="160px" size="small"
        style="width:600px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="SysRoleArray.roleName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top:50px">
        <el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addSubMit('SysRoleArray')" size="mini">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹出层 -->
    <el-dialog title="编辑" :visible.sync="dialogFormEdit" width="700px" :close-on-click-modal="false">
      <el-form :model="SysRoleArray" :rules="rules" ref="SysRoleArray" label-width="160px" size="small"
        style="width:600px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="SysRoleArray.roleName" style="width: 300px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top:50px">
        <el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="editSubMit('SysRoleArray')" size="mini">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 授权弹出层 -->
    <el-drawer title="角色权限配置" :visible.sync="drawerFormAuthorization" :with-header="true" :show-close="false"
      :before-close="handleClose" style="overflow: auto;">
      <div class="drawer-tree">
        <el-tree ref="tree" show-checkbox :data="treeData" :props="defaultProps" node-key="id"
          :default-expanded-keys="defaultExpandedKeys" :default-checked-keys="defaultCheckedKeys"
          :check-strictly="checkStrictly"
          @node-click="handleNodeClick" style="padding-left: 5%;"></el-tree>
      </div>
      <div class="drawer-bootom-button">
        <el-dropdown trigger="click" @command="handleCommand" style="float: left;">
          <el-button size="small">
            树操作<i class="el-icon-arrow-up el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="checkStrictly">父子关联</el-dropdown-item>
            <el-dropdown-item command="unCheckStrictly">取消关联</el-dropdown-item>
            <el-dropdown-item command="checkALL">全部勾选</el-dropdown-item>
            <el-dropdown-item command="cancelCheckALL">取消全选</el-dropdown-item>
            <el-dropdown-item command="expandAll">展开所有</el-dropdown-item>
            <el-dropdown-item command="closeAll">合并所有</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-tooltip effect="dark" content="取消" placement="top-end" style="margin-left:10px">
          <el-popconfirm title="确认放弃编辑吗？" @confirm="drawerFormAuthorization = false">
            <el-button slot="reference" size="small" type="info">取消</el-button>
          </el-popconfirm>
        </el-tooltip>
        <el-button @click="saveAuthorization(false)" type="primary" size="small" :loading="authorizationLoading" ghost
          style="margin-left: 10px">仅保存</el-button>
        <el-button @click="saveAuthorization(true)" type="primary" size="small" :loading="authorizationLoading"
          style="margin-left: 10px">保存并关闭</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import moment from 'moment'
import { authorization } from '../../../authorization/authorization'
export default {
  data() {
    return {
      dialogFormAdd: false,
      dialogFormEdit: false,
      drawerFormAuthorization: false,
      authorizationLoading: false,
      checkStrictly: true,
      tableData: [],
      params: {
        roleName: "",
        page: 1,
        size: 10,
      },
      SysRoleArray: {
        id: "",
        roleName: "",
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
      },
      total: 0,
      sizes: [10, 15],
      SysRoleOption: [],
      treeData: [],
      allTreeKeys: [],
      hasPermission: [],
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      }
    };
  },
  created() {
    this.querySysRoleList();
    this.queryAllPermission();
  },
  methods: {
    authorization,
    handleCommand(command) {
      switch (command) {
        case 'checkALL':
          this.checkALL();
          break;
        case 'cancelCheckALL':
          this.cancelCheckALL();
          break;
        case 'expandAll':
          this.expandAll();
          break;
        case 'closeAll':
          this.closeAll();
          break;
        case 'checkStrictly':
          this.checkStrictly = false;
          break;
        case 'unCheckStrictly':
          this.checkStrictly = true;
          break;
      }
    },
    expandAll() {
      this.defaultExpandedKeys = this.allTreeKeys
    },
    closeAll() {
      this.defaultExpandedKeys = [];
      var treeNodeList = this.$refs.tree.store._getAllNodes();
      for (var i = 0; i < treeNodeList.length; i++) {
        treeNodeList[i].expanded = false;
      }
    },
    checkALL() {
      this.defaultCheckedKeys = this.allTreeKeys;
    },
    cancelCheckALL() {
      this.defaultCheckedKeys = [];
      this.$refs.tree.setCheckedKeys([]);
    },
    handleClose(done) {
      this.$confirm('确定关闭吗?')
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    handleNodeClick(data) {
      console.log(data)
    },
    doAuthorization(row) {//授权弹出层
      this.drawerFormAuthorization = true;
      this.queryRolePermission(row);
      this.SysRoleArray = row;
      this.$nextTick(() => {
        this.cancelCheckALL();
        this.closeAll();
      });
    },
    saveAuthorization(isClose) {//保存授权
      this.authorizationLoading = true;
      let keys = this.$refs.tree.getCheckedKeys();
      let data = { permissionIdList: keys, roleId: this.SysRoleArray.id };
      this.axios({
        method: "post",
        url: "/v1/web/sys-role/save-role-permission",
        data: data
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.authorizationLoading = false;
          this.queryRolePermission(this.SysRoleArray);
        } else {
          this.$message.error(res.data.message);
        }
      });

      if (isClose) {
        this.drawerFormAuthorization = false;
      }
    },
    queryRolePermission(role) {
      let param = { roleId: role.id };
      this.axios({
        method: "get",
        url: "/v1/web/sys-role/select-role-permission",
        params: param,
      }).then((res) => {
        this.hasPermission = res.data.data;
        this.defaultCheckedKeys = [];
        for (let i = 0; i < this.hasPermission.length; i++) {
          this.defaultCheckedKeys.push(this.hasPermission[i].id);
        }

        this.defaultExpandedKeys = this.defaultCheckedKeys;
      });
    },
    queryAllPermission() {
      this.axios({
        method: "get",
        url: "/v1/web/sys-role/select-all-permission",
      }).then((res) => {
        this.treeData = res.data.data.nodeList;
        this.allTreeKeys = res.data.data.allKeys;
      });
    },
    querySysRoleList() {//获取角色名称列表
      this.axios({
        method: "get",
        url: "/v1/web/sys-role/select-sys-role",
        params: this.params,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    add() {//新增弹出层
      this.dialogFormAdd = true;
      this.SysRoleArray = {};
    },
    addSubMit(formName) {//新增提交
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.axios({
            method: "post",
            url: "/v1/web/sys-role/add-sys-role",
            data: this.SysRoleArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormAdd = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.querySysRoleList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      })
    },
    edit(row) {//修改弹出层
      this.dialogFormEdit = true;
      this.SysRoleArray = row;
    },
    editSubMit(formName) {//修改提交
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.axios({
            method: "post",
            url: "/v1/web/sys-role/update-sys-role/" + this.SysRoleArray.id,
            data: this.SysRoleArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormEdit = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.querySysRoleList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      })
    },
    deleteType(row) {//删除提交
      this.axios({
        method: "delete",
        url: "/v1/web/sys-role/delete-sys-role/" + row.id,
        params: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.querySysRoleList();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    dateFormat: function (row) {
      if (row.createTime !== null && row.createTime !== undefined) {
        return moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.querySysRoleList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.querySysRoleList();
    },
    checkData() {
      this.querySysRoleList();
    },
    reset() {
      this.params = { page: 1, size: 10 };
      this.querySysRoleList();
    },
  },
};
</script>

<style scoped>
.drawer-bootom-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
.drawer-tree{
  height: 92%;
  display: block;
  overflow-y: scroll;
}
</style>
